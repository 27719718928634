import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "buttonToDisable"];

  connect() {
    this.element.querySelectorAll("input, select").forEach((input) => {
      input.addEventListener("change", () => {
        this.buttonTarget.classList.remove("btn-primary--disabled");
        this.buttonTarget.disabled = false;
        this.buttonToDisableTargets.forEach((b) => {
          b.classList.remove("btn-danger", "btn-success");
          b.classList.add("btn-primary--disabled");
          b.disabled = true;
        });
      });
    });
  }
}
